import React, { useState } from 'react'
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import { useDispatch } from 'react-redux';
import { enddate, startdate } from '../redux/Actions';

const Calendar = () => {
    const [dates, setDatesState] = useState({
        startDate:'DD-MM-YYYY',
        endDate:  'DD-MM-YYYY'
      }); 
    const dispatch =useDispatch()
      const setDates = (e, { startDate, endDate }) => {
        setDatesState({
          startDate: startDate.format("DD-MM-YYYY"),
          endDate: endDate.format("DD-MM-YYYY")
        });
        dispatch(startdate(startDate))
        dispatch(enddate(endDate))
      };
    const yesterdayDate=()=>{
        const currentDate = new Date();
        const yesterday = new Date(currentDate);
        yesterday.setDate(currentDate.getDate() - 1);
        return [yesterday,yesterday]
    }
    const last7days=()=>{
        const currentDate = new Date();
        const yesterday = new Date(currentDate);
        yesterday.setDate(currentDate.getDate() - 7);
        return [yesterday,currentDate]
    }
    const last30days=()=>{
        const currentDate = new Date();
        const yesterday = new Date(currentDate);
        yesterday.setDate(currentDate.getDate() - 30);
        return [yesterday,currentDate]
    }
    const thismonth=()=>{
        const currentDate = new Date();
        const startOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        const startOfNextMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1);
        const endOfMonth = new Date(startOfNextMonth.getTime() - 1);
        // console.log('startOfMonth,endOfMonth',startOfMonth,endOfMonth)
        return [startOfMonth,endOfMonth]
    }
    const lastmonth=()=>{
        const currentDate = new Date();
        const startofpreviousMonth = new Date(currentDate.getFullYear(), currentDate.getMonth()-1, 1);
        const startOfcurrentmonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        const endOfpreviousMonth = new Date(startOfcurrentmonth.getTime() - 1)
        return [startofpreviousMonth,endOfpreviousMonth]
    }
      const ranges = {
          Today: [new Date(),new Date()],
          Yesterday: yesterdayDate(),
          "Last 7 Days": last7days(),
          "Last 30 Days": last30days(),
          "This Month": thismonth(),
          "Last Month":lastmonth()
        };
  return (
    <>
      
      <div className='d_flex_center ' style={{position:'relative'}}>
       
       <DateRangePicker
         onApply={setDates}
         initialSettings={{ startDate: new Date(), endDate: new Date(), ranges: ranges }}>    
         <input
           type="text"
           value={dates.startDate + " to " + dates.endDate}
           className="Date_input"
         />
         
       </DateRangePicker>
       <img className="calendar_icon" src="/assets/images/Caledar_icon.svg" alt="calendar"/>
     </div>
    </>
  
  )
}

export default Calendar
