import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { toast, ToastContainer } from "react-toastify";
import { deleteMap } from "../API";
import DeleteIcon from "@mui/icons-material/Delete";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "#282F42",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
};

function LogoutModal(props) {
  const toastmsg = (msg) => toast(msg);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = (reason) => {
    if (reason && reason == "backdropClick") return;
    setOpen(false);
  };
  const token = localStorage.getItem("token");
  const handlemapdelete = () => {
    deleteMap(props.item.mapId, token)
      .then((res) => {
        toastmsg(res.data.message);
        props.changerefresh();
        handleClose();
      })
      .catch((err) => {
        console.log("Delete Map Response", err);
      });
  };
  return (
    <>
      <button className="EditMap_button Delete_map_btn" onClick={handleOpen}>
        <DeleteIcon sx={{ color: "#E96C67", fontSize: "20px" }} /> Delete Map{" "}
      </button>
      <Modal
        open={open}
        // BackdropProps={false}
        onHide={handleClose}
        // onClose={handleClose}
        backdrop="static"
        keyboard="false"
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="Cancel-order-Modal-Mainwrapper">
            {/* <img src="/assets/images/cancel-order.png"/> */}
            <p> Are you sure want to Delete Map {props.item.mapName} ?</p>
            <div className="Modal-button-Main-wrapper">
              <Button
                className="Modal-button-Main-btn yes-red-active"
                onClick={() => {
                  handlemapdelete();
                }}
              >
                {" "}
                Yes
              </Button>
              <Button className="Modal-button-Main-btn" onClick={handleClose}>
                {" "}
                No
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
      <ToastContainer />
    </>
  );
}
export default React.memo(LogoutModal);
