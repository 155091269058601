import React, { useCallback, useEffect, useRef, useState } from "react";
import Header from "../../components/Header";
import Location from "./Location";
import DashboardSideBar from "../../subcomponents/DashboardSideBar";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import LocationModal from "./LocationModal";

import DashboardBottomBar from "../../components/DashboardBottomBar";

function Map(props) {
  const [selectfleet, setselectfleet] = useState("");
  const [status, setstatus] = useState("");
  const emailId = localStorage.getItem("useremail");
  const navigate = useNavigate();
  const toastmsg = (msg) => toast(msg);
  const { sidebarcollapse } = useSelector((state) => state.login);
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();
  const [locationsList , setLocationsList] = useState([])
  useEffect(() => {
    if (
      !localStorage.getItem("token") ||
      localStorage.getItem("token") === null ||
      localStorage.getItem("token") === "null" ||
      localStorage.getItem("token") === undefined
    ) {
      navigate("/");
    }
  }, []);
  useEffect(()=>{
    fetchLocationsByType()
      .then((result) => {
        if(result.status === 200){
        setLocationsList(result?.data?.data)
        }
      })
      .catch((error) => {
        // Handle errors if needed
        console.error(error);
      });

  },[])
  const [open, setOpen] = useState(false);
  const fetchLocationsByType = (async () => {
    try{
        const result = await axios.post(
          `${process.env.REACT_APP_BASE_URL}v2/getLocationsByLocationType`,
          {
            fleetId:"64ec5abb5e561268fa56f1fe",
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        return result;
    }
    catch(err){
        console.error(err)
        // toast.error("Something went wrong");
    }
  })
  const handleOpen = () => {
        setOpen(true);
      };
  const isopen = useSelector((state) => state.dashboardopenReducer);
  const naviate = useNavigate();
 
  return (
    <>
    <LocationModal open={open}/>
      <Header />
      <div className="Fleet_page_Whole_content_wrapper_main">
        {/* {console.log('robotlistData',robotlistData)} */}
      </div>
      <div className="container-fluid">
        <div className="row">
          <div
            className={sidebarcollapse ? "col-md-2 collapseWidth" : "col-md-2"}
          >
            <DashboardSideBar
              dashboardopen={props.dashboardopen}
              active="robotpage"
            />
            <DashboardBottomBar active="robotpage" />
          </div>

          <div
            className={`${
              sidebarcollapse ? "col-md-10 collapseWidth" : "col-md-10"
            }`}
          >
            <div
              style={{ height: "100%" }}
              className={isopen.userlogindata ? "" : ""}
            >
            <div className="my-4 text-center">
            <button className="my-2 p-2 bg-ottonomyBlue rounded-md" onClick={handleOpen}>
                <p className="text-lg text-white">
                    Add Location
                </p>
                </button>
                <div className="grid grid-cols-2 gap-3">
                {locationsList.map((item, index) => (
          <Location details={item} key={index}/>
        ))}
        </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Map;
