import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { useRef } from "react";
import { getFleetListUser } from "../API";
import { getRobotDirectionbyMapbox, robotmsgAction } from "../redux/Actions";
import { useNavigate } from "react-router-dom";

function MapboxRobot(props) {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const robotDetails = useSelector((state) => state.robotDetailsReducer);
  mapboxgl.accessToken =
    "pk.eyJ1IjoibXJsYWtzaHlhZ3VwdGEiLCJhIjoiY2xpZWZlcTJjMGo4ZDNkczBwc2ZmcW1qMyJ9.Kurk7BS04invJcCwS0m4Pg";
  let destination;
  const { robotDirections } = useSelector(
    (state) => state.robotDirectionsReducer
  );
  const robotlinecolor = [
    "#3246B2",
    "#53B6CC",
    "#E68D40",
    "#74CB6B",
    "#D04622",
    "#292F40",
  ];

  const { robotmsgData } = useSelector((state) => state.robotMessageReducer);
  const { fleetList } = useSelector((state) => state.fleetList);

  const [destinationMarker, setDestinationMarker] = useState(null);
  const [nogozonescoordinates, setnogozonescoordinates] = useState(
    props.nogozones?.map((data) => [data.lng, data.lat])
  );
  const [geofencecoordinates, setgeofencecoordinates] = useState(
    props.geofencezone?.map((data) => [data.lng, data.lat])
  );

  const mapRef = useRef(null);
  const [robotMarker, setRobotMarker] = useState(null);
  const [polygonarray, setPolygonarray] = useState([]);
  const robotMarkerDiv = document.createElement("div");
  robotMarkerDiv.style.width = "35px";
  robotMarkerDiv.style.height = "35px";
  robotMarkerDiv.style.backgroundImage =
    "url(/assets/images/robot-dropoff-icon.svg)";
  robotMarkerDiv.style.borderRadius = "100%";
  robotMarkerDiv.style.animation = "example 1s ease-in-out infinite";

  const destinationMarkerDiv = document.createElement("div");
  destinationMarkerDiv.style.width = "35px";
  destinationMarkerDiv.style.height = "35px";
  destinationMarkerDiv.style.backgroundImage =
    "url(/assets/images/robot-icon-dropoff_1.svg)";
  destinationMarkerDiv.style.borderRadius = "100%";
  destinationMarkerDiv.style.animation = "example 1s ease-in-out infinite";

  const [markerArray, setmarkerArray] = useState([]);
  const [map, setMap] = useState(null);
  const [zoom, setZoom] = useState(15);

  const getrunningstatuscolor = (robotRunningState) => {
    switch (robotRunningState) {
      case "AUTO_SYSTEM_FAULT":
        return "Dashboard_page_individual_robot_robotstatus_div AutoSystem_fault_error_p";
      case "ERROR":
        return "Dashboard_page_individual_robot_robotstatus_div AutoSystem_fault_error_p";
      case "AUTO_OBSTACLE":
        return "Dashboard_page_individual_robot_robotstatus_div AutoObstacle_teleop_obstacle_p";
      case "AUTO_RUN_OBSTACLE":
        return "Dashboard_page_individual_robot_robotstatus_div Teleop_autoRun_p";
      case "AUTO_RUN_MOVING":
        return "Dashboard_page_individual_robot_robotstatus_div AutoRun_moving_p";
      case "AUTO_RUN":
        return "Dashboard_page_individual_robot_robotstatus_div Teleop_autoRun_p";
      case "TELE_OP_OBSTACLE":
        return "Dashboard_page_individual_robot_robotstatus_div AutoObstacle_teleop_obstacle_p";
      case "TELE_OP_MOVING":
        return "Dashboard_page_individual_robot_robotstatus_div teleopMoving_bordertop_p";
      case "TELE_OP":
        return "Dashboard_page_individual_robot_robotstatus_div Teleop_autoRun_p";
      case "PAYLOAD_STATE":
        return "Dashboard_page_individual_robot_robotstatus_div payloadState_bordertop_p";
      case "MANUAL":
        return "Dashboard_page_individual_robot_robotstatus_div Teleop_autoRun_p";
      default:
        return "Dashboard_page_individual_robot_robotstatus_div defaultBorderTop_P";
    }
  };

  const getrobotStatus = (status) => {
    switch (status) {
      case "UNAVAILABLE":
        return '<p class="color-red Dashboard_page_robotStatusClass">  UNAVAILABLE </p>';
      case "AVAILABLE":
        return '<p class="color-green Dashboard_page_robotStatusClass"> AVAILABLE  </p>';
      case "ERROR":
        return '<p class="color-yellow Dashboard_page_robotStatusClass"> Error</p>';
      default:
        return '<p class="color-red Dashboard_page_robotStatusClass"> {status}</p>';
    }
  };

  const getrobotIcons = (item) => {
    // console.log('item.robotBattery',item.robotBattery)
    const batteryIcon = `
    <div class="battery-icon-css">
        <span style="width: 30px; border: 1px solid #fff; border-radius: 4px; padding: 2px; height: 14px; display: inline-block;">
            <strong style="background: ${
              parseFloat(item.robotBattery).toFixed(1) <= 20
                ? "#E4261C"
                : "rgb(113, 207, 98)"
            }; width: ${parseFloat(item.robotBattery).toFixed(
      1
    )}%; display: block; height: 8px;"></strong>
        </span>
    </div>
    <p style="font-size: 10px; text-align: center; font-weight: bold;">${parseFloat(
      item.robotBattery
    ).toFixed(0)}%</p>
`;

    const signalDivWrapper = `
    <div class="Signal_div_wrapper">
        <span style="background: #fff; width: 3px; height: 3px; display: inline-block; border-radius: 3px;"></span>
        <span style="background: #fff; width: 3px; height: 6px; display: inline-block; border-radius: 3px;"></span>
        <span style="background: #fff; width: 3px; height: 9px; display: inline-block; border-radius: 3px;"></span>
        <span style="background: #fff; width: 3px; height: 12px; display: inline-block; border-radius: 3px; opacity: 0.5;"></span>
        <span style="background: #fff; width: 3px; height: 15px; display: inline-block; border-radius: 3px; opacity: 0.5;"></span>
    </div>
`;

    const robotBoxContent = `
        ${batteryIcon}
        ${signalDivWrapper}
`;
    return robotBoxContent;
  };

  const [robotpath, setrobotpath] = useState([]);
  let offset = 0.00003;

  useEffect(() => {
    let tempvar = [];
    const initializeMap = () => {
      const newMap = new mapboxgl.Map({
        container: "map-container-id",
        style: "mapbox://styles/mrlakshyagupta/climr57of00le01pgc0jjgfoh",
        center: [
          props.fleetData?.longitude
            ? props.fleetData?.longitude
            : fleetList[0]?.longitude,
          props.fleetData?.latitude
            ? props.fleetData?.latitude
            : fleetList[0]?.latitude,
        ],
        zoom: zoom,
      });
      // initialize robot marker
      // const newRobotMarker = new mapboxgl.Marker(robotMarkerDiv)
      // .setLngLat([
      //   0,
      //   0,
      // ])
      // .addTo(newMap);

      fleetList?.forEach((item) => {
        if (item?.fleetId === props?.fleetData?.fleetId) {
          item?.robots?.forEach((robot, index) => {
            const el = document.createElement("div");
            el.className = `marker-${index + 1}`;
            let newMarker = new mapboxgl.Marker(el)
              .setLngLat([robot.longitude, robot.latitude])
              .setPopup(
                new mapboxgl.Popup()
                  .setHTML(
                    '<div class="Dasboard_page_individual_robot_box_wrapper">' +
                      '  <div class="d-flex-center Dasboard_page_individual_robot_box_1">' +
                      "    <p>OTTOBOT</p>" +
                      '    <div class="' +
                      getrunningstatuscolor(robot.robotRunningState) +
                      '">' +
                      '      <span class="state_border">' +
                      robot.robotRunningState +
                      "</span>" +
                      "    </div>" +
                      "  </div>" +
                      '  <div class="d-flex-center Dasboard_page_individual_robot_box_2">' +
                      "    <div>" +
                      '      <h4 class="Dasboard_page_individual_robot_h4">' +
                      robot.robotId +
                      "</h4>" +
                      "      " +
                      getrobotStatus(robot.robotStatus) +
                      "    </div>" +
                      "    <div>" +
                      '      <div class="Dashboard_page_robot_div_icons">' +
                      getrobotIcons(robot) +
                      "</div>" +
                      "      <div>" +
                      "        " +
                      (robot.robotStatus === "AVAILABLE"
                        ? '<button class="Pause_btn">Pause</button>'
                        : '<button class="Play_btn">Play</button>') +
                      "" +
                      "      </div>" +
                      "    </div>" +
                      "  </div>" +
                      ' <div class="d-flex-center Dasboard_page_individual_robot_box_1">' +
                      "<div>  <p>Coordinates </p>" +
                      '<p class="Coordinates_p">' +
                      robot.latitude +
                      "," +
                      robot.longitude +
                      "</p>" +
                      "</div>" +
                      "<div> <p>Status </p>" +
                      "<p class=" +
                      (robot.connectionStatus === "OFFLINE"
                        ? "p-color-red-two"
                        : "p-color-green-two") +
                      ">" +
                      robot.connectionStatus +
                      "</p>" +
                      "</div>" +
                      "</div>" +
                      "</div>"
                  )
                  .on("open", function () {
                    const divElement = document.querySelector(
                      ".Dasboard_page_individual_robot_box_wrapper"
                    );
                    if (divElement) {
                      divElement.addEventListener("click", function () {
                        let latitude;
                        let longitude;
                        fleetList.map((ttt) => {
                          if (ttt.fleetId === robot.fleetId) {
                            ttt.robots.map((itmmm) => {
                              if (itmmm.robotId === robot.robotId) {
                                latitude = itmmm.latitude;
                                longitude = itmmm.longitude;
                              }
                            });
                          }
                        });
                        navigate(`/robotPage/${robot.robotId}`, {
                          state: {
                            data: robot,
                            latitude: latitude,
                            longitude: longitude,
                            robotlistData: props.fleetData?.robots,
                            marker: `marker-${index + 1}`,
                          },
                        });
                      });
                    }
                  })
              )
              .addTo(newMap);
            newMarker.id = `robot-${index}`;
            tempvar.push(newMarker);
          });
        }
      });
      // initialize destination marker
      // const newDestinationMarker = new mapboxgl.Marker(destinationMarkerDiv)
      //   .setLngLat([0, 0])
      //   .addTo(newMap);
      // setDestinationMarker(newDestinationMarker);
      // initialize nogozone
      newMap.on("move", () => {
        setZoom(newMap.getZoom().toFixed(2));
      });
      setmarkerArray([...tempvar]);
      setMap(newMap);
      // mapRef.current = newMap;
      newMap &&
        newMap.on("load", () => {
          newMap.addSource("nogozone", {
            type: "geojson",
            data: {
              type: "Feature",
              geometry: {
                type: "Polygon",
                // These coordinates outline Maine.
                coordinates: [nogozonescoordinates],
              },
            },
          });
          newMap.addLayer({
            id: "nogozoneoutline",
            type: "line",
            source: "nogozone",
            layout: {},
            paint: {
              "line-color": "#f00",
              "line-width": 3,
            },
          });
        });

      // Chick-Fil-A 2D Map
      if (process.env.REACT_APP_BASE_URL.includes("chick")) {
        newMap.on("load", () => {
          // const layers = newMap.getStyle().layers;

          // // Find the index of the first symbol layer in the map style.
          // let firstSymbolId;
          // for (const layer of layers) {
          //   if (layer.type === "symbol") {
          //     firstSymbolId = layer.id;
          //     break;
          //   }
          // }
          newMap.addSource("chickfila-map", {
            type: "image",
            url: `${process.env.PUBLIC_URL}/assets/images/chickfila-googlemap.png`,
            coordinates: [
              [-95.5451623748, 29.784804206],
              [-95.5346301602, 29.7849896156],
              [-95.5344598025, 29.7777023516],
              [-95.5449909197, 29.7775141246],
            ],
          });
          newMap.addLayer(
            {
              id: "chickfila-radar-layer",
              type: "raster",
              source: "chickfila-map",
              paint: {
                "raster-fade-duration": 0,
                "raster-opacity": 1,
                // "raster-"
              },
            }
            // ,
            // firstSymbolId
          );

          // newMap.addSource("chickfila-ground-map", {
          //   type: "image",
          //   url: `${process.env.PUBLIC_URL}/assets/images/chickfila-ground-map.png`,
          //   coordinates: [
          //     [-95.5451623748, 29.784804206],
          //     [-95.5346301602, 29.7849896156],
          //     [-95.5344598025, 29.7777023516],
          //     [-95.5449909197, 29.7775141246],
          //   ],
          // });
          // newMap.addLayer(
          //   {
          //     id: "chickfila-ground-radar-layer",
          //     type: "raster",
          //     source: "chickfila-ground-map",
          //     paint: {
          //       "raster-fade-duration": 0,
          //       // "raster-opacity": 0.5,
          //     },
          //   },
          //   firstSymbolId
          // );
        });
      }

      // initialize geofencezone
      newMap &&
        newMap.on("load", () => {
          // Add a data source containing GeoJSON data.
          newMap.addSource("geofence", {
            type: "geojson",
            data: {
              type: "Feature",
              geometry: {
                type: "Polygon",
                // These coordinates outline Maine.
                coordinates: [geofencecoordinates],
              },
            },
          });
          newMap.addLayer({
            id: "geofenceoutline",
            type: "line",
            source: "geofence",
            layout: {},
            paint: {
              "line-color": "#f00",
              "line-width": 3,
            },
          });
        });

      // initialize fleetzones
      let newpolygonarray = [];
      let offset = 0.00003;
      props.fleetzones?.map((polygon, index) => {
        newpolygonarray.push(
          polygon?.map((data) => [data.lng, data.lat + offset])
        );
        newMap &&
          newMap.on("load", () => {
            // Add a data source containing GeoJSON data.
            newMap.addSource("fleetzone-" + index, {
              type: "geojson",
              data: {
                type: "Feature",
                geometry: {
                  type: "Polygon",
                  // These coordinates outline Maine.
                  coordinates: [newpolygonarray[index]],
                },
              },
            });
            newMap.addLayer({
              id: "fleetzoneoutline-" + index,
              type: "fill",
              source: "fleetzone-" + index,
              layout: {},
              paint: {
                "fill-color": "#7859bc",
                "fill-opacity": 1,
              },
            });
          });

        return newpolygonarray;
      });

      newMap &&
        newMap.on("move", () => {
          setZoom(newMap.getZoom().toFixed(2));
        });
      setPolygonarray(newpolygonarray);
    };

    fleetList.forEach((item, key) => {
      item?.robotsDetails?.forEach((robot, index) => {
        // {console.log('item?.robotsDetails',item)}
        map &&
          map.on("load", () => {
            const sourceId = `robotpath-${key}-${index}`; // Generate a unique source ID
            const layerId = `robotpathlayer-${key}-${index}`; // Generate a unique layer ID

            map.addSource(sourceId, {
              type: "geojson",
              data: {
                type: "Feature",
                geometry: {
                  type: "LineString",
                  coordinates: [
                    robot.path?.map((data) => [data.lng, data.lat]),
                  ],
                },
              },
            });

            map.addLayer({
              id: layerId,
              type: "line",
              source: sourceId,
              layout: {},
              paint: {
                "line-color": robotlinecolor[index],
                "line-width": 5,
              },
            });
          });
      });
    });
    //     fleetList.forEach((item , key)=>{
    //       item?.robotsDetails?.forEach((robot, index)=>{

    //         // console.log('robot.path',robot.path?.map((data) => [data.lng, data.lat]))
    //         map && map.on("load", () => {
    //         // Add a data source containing GeoJSON data.
    //           map.addSource(`robotpath-${index+1}`, {
    //             type: "geojson",
    //             data: {
    //               type: "Feature",
    //               geometry: {
    //                 type: "Polygon",
    //                 // These coordinates outline Maine.
    //                 coordinates:[robot.path?.map((data) => [data.lng, data.lat])],
    //               },
    //             },
    //           });

    //           map.addLayer({
    //             id: `robotpathlayer-${index}`,
    //             type: "line",
    //             source: `robotpath-${index+1}`,
    //             layout: {},
    //             paint: {
    //               "line-color": "#0f0",
    //               "line-width": 5,
    //             },
    //           });
    //         });
    //      })
    //  })
    // setRobotMarker(newRobotMarker);
    // setMap(newMap);
    if (!map) {
      initializeMap();
    }
  }, [
    map,
    setMap,
    props.nogozones,
    props.fleetzones,
    props.geofencezone,
    fleetList,
  ]);
  const checkActionId = (commandListData, props) => {
    // console.log(direction);
    // console.log(commandListData);
    if (
      !localStorage.getItem("actionId") ||
      localStorage.getItem("actionId") === "null"
    ) {
      // console.log("FIRST SAVE");
      localStorage.setItem(
        "actionId",
        JSON.stringify({
          actionType: commandListData.actionType,
          actionId: commandListData.actionId,
        })
      );
      // console.log("First ", localStorage.getItem("actionId"));
      return true;
    } else {
      if (
        commandListData.actionId !==
        JSON.parse(localStorage.getItem("actionId")).actionId
      ) {
        // console.log(direction);
        // console.log(commandListData.actionId);
        // console.log("ACTION ID NOT EQUAL");
        localStorage.setItem(
          "actionId",
          JSON.stringify({
            actionType: commandListData.actionType,
            actionId: commandListData.actionId,
          })
        );
        // console.log("Second ", localStorage.getItem("actionId"));
        return true;
      } else {
        // props.setToggleDirection(false);
        // console.log(setToggleDirection(false));
        // console.log("ACTION ID SAME");
        return false;
      }
    }
  };

  const updateRobotpath = () => {
    const map = mapRef.current;
    if (!props.robotpathcheck) {
      if (
        robotDetails[props.robotId]?.hasOwnProperty("path") &&
        robotDetails[props.robotId].path.length > 0 &&
        map.isStyleLoaded()
      ) {
        const newrobotPath = robotDetails[props.robotId].path.map(
          (pathDirection) => [pathDirection.lng, pathDirection.lat]
        );
        const mySource = map.getSource("robotPath");
        map &&
          mySource &&
          mySource.setData({
            type: "Feature",
            properties: {},
            geometry: {
              type: "LineString",
              coordinates: newrobotPath,
            },
          });
        map.getLayer("path") &&
          map.setPaintProperty("path", "line-color", "#0f0");
      }
    } else {
      if (
        robotDetails[props.robotId]?.hasOwnProperty("robotCommandList") &&
        robotDetails[props.robotId].robotCommandList.length > 0 &&
        robotDetails[props.robotId].robotCommandList.find(
          (x) => x.actionPriority === 1
        ).actionType === "MOVELOCATION" &&
        map.isStyleLoaded() &&
        checkActionId(
          robotDetails[props.robotId].robotCommandList.find(
            (x) => x.actionPriority === 1
          ),
          props
        )
      ) {
        // console.log("INSIDE IF IF")

        origin = {
          lat: robotDetails[props.robotId].robotCommandList.find(
            (x) => x.actionPriority === 1
          ).latitude,
          lng: robotDetails[props.robotId].robotCommandList.find(
            (x) => x.actionPriority === 1
          ).longitude,
        };
        // console.log(origin);
        destination = {
          lat: props.robotlatitude,
          lng: props.robotlongitude,
        };

        dispatch(getRobotDirectionbyMapbox(origin, destination));
      }
      // console.log("are we here?", JSON.parse(localStorage.getItem("directionRoutes")))

      // map.current.removeLayer("robotPathOutline");
      // map.current.removeSource("robotPath");
      // console.log("coordinates", mapboxPath)
      // console.log(robotPath)

      if (robotDirections?.hasOwnProperty("routes")) {
        // console.log(props.robotpathcheck)

        const mapboxPath = robotDirections.routes[0]?.geometry.coordinates;
        const mySource = map.getSource("robotPath");
        map &&
          mySource &&
          mySource.setData({
            type: "Feature",
            properties: {},
            geometry: {
              type: "LineString",
              coordinates: mapboxPath,
            },
          });
        map.getLayer("path") &&
          map.setPaintProperty("path", "line-color", "#A020F0");
      }
    }
  };
  useEffect(() => {
    // if (map.current) return; // initialize map only once

    // const initializeMap = () => {
    // const newMap = new mapboxgl.Map({
    //   container: "map-container-id",
    //   style: "mapbox://styles/mrlakshyagupta/climr57of00le01pgc0jjgfoh",
    //   center: [
    //     fleetList[0].longitude,
    //     fleetList[0]?.latitude,
    //   ],
    //   zoom: zoom,
    // });
    // initialize robot marker

    // initialize nogozone

    // map.current = newMap;

    // }

    // if (!map) {
    //   initializeMap();
    // }
    return () => {};
    // return () => {
    //   if (map) {
    //     console.log("removing? this map without?")
    //     console.log(map)
    //     map&&map.remove();
    //     console.log("after remove",map)
    //     setMap()
    //     console.log("after set",map)
    //   }
    // };
  }, [
    map,
    props.nogozones,
    props.fleetzones,
    props.geofencezone,
    setMap,
    fleetList,
  ]);

  useEffect(() => {
    if (
      (robotmsgData &&
        robotmsgData.length > 0 &&
        !robotmsgData.find((robotData) => robotData.location === "zaragoza")) ||
      !robotmsgData
    ) {
      getFleetListUser(
        localStorage.getItem("useremail"),
        localStorage.getItem("token")
      ).then((res) => {
        dispatch(robotmsgAction(res.data.fleet));
      });
    }

    if (robotmsgData && robotmsgData.length > 0) {
    }
    if (map && map.isStyleLoaded()) {
      if (
        robotmsgData &&
        robotmsgData.find((robotData) => robotData.location === "zaragoza")
      ) {
        !map.getLayer("zaragoza-okoutline") &&
          map.addLayer({
            id: "zaragoza-okoutline",
            type: "line",
            source: {
              type: "vector",
              url: "mapbox://mrlakshyagupta.cliockddp17ir2onppxm67ul4-2322c",
            },
            "source-layer": "Zaragoza-OK",
            layout: {
              "line-join": "round",
              "line-cap": "round",
            },
            paint: {
              "line-color": "#0000ff",
              "line-width": 2,
            },
          });
        !map.getLayer("zaragoza-goodoutline") &&
          map.addLayer({
            id: "zaragoza-goodoutline",
            type: "line",
            source: {
              type: "vector",
              url: "mapbox://mrlakshyagupta.cliockw3y0x522ophzjv42pw5-05s1c",
            },
            "source-layer": "Zaragoza-GOOD",
            layout: {
              "line-join": "round",
              "line-cap": "round",
            },
            paint: {
              "line-color": "#027148",
              "line-width": 2,
            },
          });
        !map.getLayer("zaragoza-difficultoutline") &&
          map.addLayer({
            id: "zaragoza-difficultoutline",
            type: "line",
            source: {
              type: "vector",
              url: "mapbox://mrlakshyagupta.cliocljvn0yvs2cphplde8mwp-65qz7",
            },
            "source-layer": "Zaragoza-DIFFICULT",
            layout: {
              "line-join": "round",
              "line-cap": "round",
            },
            paint: {
              "line-color": "#8B0000",
              "line-width": 2,
            },
          });
        !map.getLayer("zaragoza-nooutline") &&
          map.addLayer({
            id: "zaragoza-nooutline",
            type: "line",
            source: {
              type: "vector",
              url: "mapbox://mrlakshyagupta.cliocqzf906lb2bo9xa6o69r5-5ubuf",
            },
            "source-layer": "Zaragoza-NO",
            layout: {
              "line-join": "round",
              "line-cap": "round",
            },
            paint: {
              "line-color": "#FF0000",
              "line-width": 2,
            },
          });
      }

      // map.on("load", () => {
      // console.log(map)
      // console.log(map.getSource("robotPath"))
      // newMap.addSource("robotPath", {
      //   type: "geojson",
      //   data: {
      //     type: "Feature",
      //     geometry: {
      //       type: "LineString",
      //       // These coordinates outline Maine.
      //       coordinates: robotPath,
      //     },
      //   },
      // });
      // newMap.addLayer({
      //   id: "robotPathOutline",
      //   type: "line",
      //   source: "robotPath",
      //   layout: {
      //     "line-join": "round",
      //     "line-cap": "round",
      //   },
      //   paint: {
      //     "line-color": "#0f0",
      //     "line-width": 4,
      //   },
      // });
      // const updatePathOnMap = () => {
      if (
        !map.getSource("robotPath") &&
        ((robotDetails[props.robotId]?.hasOwnProperty("path") &&
          robotDetails[props.robotId].path.length > 0) ||
          (robotDetails[props.robotId]?.hasOwnProperty("robotCommandList") &&
            robotDetails[props.robotId].robotCommandList.length > 0 &&
            robotDetails[props.robotId].robotCommandList.find(
              (x) => x.actionPriority === 1
            ).actionType === "MOVELOCATION"))
      ) {
        var newrobotPath = [];
        newrobotPath =
          robotDetails[props.robotId].path &&
          robotDetails[props.robotId].path.map((pathDirection) => [
            pathDirection.lng,
            pathDirection.lat,
          ]);
        // const robotGeojson = {
        //   type: "Feature",
        //   properties: {},
        //   geometry: {
        //     type: "LineString",
        //     coordinates: newrobotPath,
        //   },
        // };

        // if (newMap.getSource("robotPath")) {
        //   newMap.getSource("robotPath").setData(robotGeojson);
        // } else {
        map.addSource("robotPath", {
          type: "geojson",
          data: {
            type: "Feature",
            properties: {},
            geometry: {
              type: "LineString",
              coordinates: newrobotPath,
            },
          },
        });

        map.addLayer({
          id: "path",
          type: "line",
          source: "robotPath",
          layout: {
            "line-join": "round",
            "line-cap": "round",
          },
          paint: {
            "line-color": "#0f0",
            "line-width": 5,
          },
        });
      }
      // };

      //   updatePathOnMap();
      // }
      // });
    }
    // move robot marker
    // if (robotMarker) {
    //   robotMarker.setLngLat([
    //     fleetList[0]?.longitude,
    //     fleetList[0]?.latitude,
    //   ]);
    // }
    if (markerArray.length > 0) {
      fleetList?.map((item) => {
        item?.robots?.map((robot, index) => {
          markerArray[index]?.setLngLat([
            robot.longitudeSlam,
            robot.latitudeSlam,
          ]);
          return null;
        });
        return null;
      });
    }
    // move destination marker
    if (
      destinationMarker &&
      robotDetails[props.robotId]?.hasOwnProperty("robotCommandList") &&
      robotDetails[props.robotId]?.robotCommandList.length > 0 &&
      robotDetails[props.robotId]?.robotCommandList.find(
        (x) => x.actionPriority === 1
      )
    ) {
      // console.log(
      //   robotDetails[props.robotId].robotCommandList[0].longitude,
      //   robotDetails[props.robotId].robotCommandList[0].latitude,
      //   destinationMarker,
      //   robotDetails[props.robotId].hasOwnProperty("robotCommandList"),

      //   robotDetails[props.robotId].robotCommandList.length > 0
      // );
      // console.log(robotDetails[props.robotId].robotCommandList)
      // console.log(robotDetails[props.robotId].robotCommandList.find(x => x.actionPriority === 1)
      // )
      destinationMarker.setLngLat([
        robotDetails[props.robotId].robotCommandList.find(
          (x) => x.actionPriority === 1
        ).longitude,
        robotDetails[props.robotId].robotCommandList.find(
          (x) => x.actionPriority === 1
        ).latitude,
      ]);
    }

    // move map
    // if (!map) return; // wait for map to initialize

    // console.log(map, "MAP");

    // if (map.getSource("robotPath")) {
    //   map.getSource("robotPath").setData({
    //     type: "Feature",
    //     properties: {},
    //     geometry: {
    //       type: "LineString",
    //       coordinates: newrobotPath,
    //     },
    //   });
    // }
    // console.log(robotDetails[props.robotId].robotCommandList[0].latitude)

    let offset = 0.00003;
    if (props.nogozones?.length > 0) {
      setnogozonescoordinates(
        props.nogozones?.map((data) => [data.lng, data.lat + offset])
      );
    }
    if (props.geofencezone?.length > 0) {
      setgeofencecoordinates(
        props.geofencezone?.map((data) => [data.lng, data.lat + offset])
      );
    }

    if (props.fleetzones?.length > 0) {
      let newpolygonarray = [];
      props.fleetzones?.map((polygon, index) => {
        newpolygonarray.push(polygon.map((data) => [data.lng, data.lat]));
        return newpolygonarray;
      });
      setPolygonarray(newpolygonarray);
    }

    // console.log('props.nogozones',props.nogozones)
    // console.log('nogozonescoordinates',nogozonescoordinates,'geofencecoordinates',geofencecoordinates)
    // console.log(props.robotpathcheck);

    // if (!props.robotpathcheck) {
    //robot path from robot

    // Add a data source containing GeoJSON data.
    if (robotDetails[props.robotId]) {
      updateRobotpath();
    }
    // setRobotPath(newrobotPath);
    // console.log(robotPath);
    // console.log(robotPath)
    // map.on("load", () => {
    //   map.addSource("robotPath", {
    //     type: "geojson",
    //     data: {
    //       type: "Feature",
    //       geometry: {
    //         type: "LineString",
    //         // These coordinates outline Maine.
    //         coordinates: robotPath,
    //       },
    //     },
    //   });
    //   map.addLayer({
    //     id: "robotPathOutline",
    //     type: "line",
    //     source: "robotPath",
    // layout: {
    //   "line-join": "round",
    //   "line-cap": "round",
    // },
    // paint: {
    //   "line-color": "#0f0",
    //   "line-width": 4,
    // },
    //   });
    // });
    // } else if (props.robotpathcheck) {
    // console.log("INSIDE IF")

    // console.log(mapboxPath)
    //   map.on("load", () => {
    //     mapboxPath &&
    //       // Add a data source containing GeoJSON data.
    //       map.addSource("mapboxPath", {
    //         type: "geojson",
    //         data: {
    //           type: "Feature",
    //           properties: {},
    //           geometry: {
    //             type: "LineString",
    //             // These coordinates outline Maine.
    //             coordinates: mapboxPath,
    //           },
    //         },
    //       });

    //     map.addLayer({
    //       id: "mapboxPathOutline",
    //       type: "line",
    //       source: "mapboxPath",
    //       layout: {
    //         "line-join": "round",
    //         "line-cap": "round",
    //       },
    //       paint: {
    //         "line-color": "rgba(0,51,0,0.5)",
    //         "line-width": 4,
    //       },
    //     });
    //   });
    // }
    // }
    // return () => map.remove();
    if (nogozonescoordinates.length > 0) {
      // Create a 'LngLatBounds' with both corners at the first coordinate.
      const bounds = new mapboxgl.LngLatBounds(
        nogozonescoordinates[0],
        nogozonescoordinates[0]
      );

      // Extend the 'LngLatBounds' to include every coordinate in the bounds result.
      for (const coord of nogozonescoordinates) {
        bounds.extend(coord);
      }

      map?.fitBounds(bounds, {
        padding: 20,
      });
    } else if (geofencecoordinates.length > 0) {
      // Create a 'LngLatBounds' with both corners at the first coordinate.
      const bounds = new mapboxgl.LngLatBounds(
        geofencecoordinates[0],
        geofencecoordinates[0]
      );

      // Extend the 'LngLatBounds' to include every coordinate in the bounds result.
      for (const coord of geofencecoordinates) {
        bounds.extend(coord);
      }

      map?.fitBounds(bounds, {
        padding: 20,
      });
    }
  }, [
    robotDetails[props.robotId]?.longitude,
    robotDetails[props.robotId]?.latitude,
    robotMarker,
    markerArray,
    fleetList,
    destinationMarker,
    props.robotId,
    props.fleetzones,
    props.geofencezone,
    props.nogozones,
    props.robotpathcheck,
    dispatch,
    robotDirections,
    robotDetails,
    robotDetails[props.robotId],
    robotDetails[props.robotId]?.path,
    // robotPath
  ]);

  return (
    <div style={{ height: "80vh" }}>
      <div
        id="map-container-id"
        // ref={mapContainer}
        className="map-container"
        style={{ height: "100%" }}
      ></div>
    </div>
  );
}

export default MapboxRobot;
