import React from "react";
import PersonIcon from "@mui/icons-material/Person";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import * as yup from "yup";
import { Form, Formik, useFormik } from "formik";
import {
  Button,
  ButtonBase,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Input,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import LockIcon from "@mui/icons-material/Lock";
import { useNavigate } from "react-router-dom";
// import { getFleetListAdmin, getFleetListUser, getFleetListuser, login } from '../API/index';

import { useDispatch, useSelector } from "react-redux";
// import { useAuth0 } from '@auth0/auth0-react';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { fleetlist, login } from "../API";
import { websocketwsreducer } from "../redux/reducers";
import { clearErrors, login as userLogin } from "../redux/actions/user";
import e from "cors";
import { useEffect } from "react";
import { fleetListAndRobotStatusByUserEmail } from "../redux/actions/fleet";

function Login() {
  const notifyfialed = () => toast.error("Invalid Credentials!");
  const notifySuccess = () => toast.success("Logged in Successfully!");
  //   const {
  //     isLoading,
  //     loginWithPopup,
  //     loginWithRedirect,
  //     error,
  //     user,
  //     logout,
  //     isAuthenticated
  //   } = useAuth0();
  const isLogin = localStorage.getItem("token");

  const dispatch = useDispatch();
  const { user, loading, error } = useSelector((state) => state.login);
  const { fleetList } = useSelector((state) => state.fleetList);

  const validationSchema = yup.object({
    email: yup
      .string()
      .email("Enter a valid email")
      .required("Email is required"),
    password: yup
      .string()
      .min(5, "Password should be of minimum 5 characters length")
      .required("Password is required"),
  });
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      showPassword: true,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      dispatch(userLogin(values.email, values.password));
    },
  });
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearErrors());
    }
    if (
      localStorage.getItem("token") &&
      localStorage.getItem("token") !== "null"
    ) {
      dispatch(
        fleetListAndRobotStatusByUserEmail(localStorage.getItem("useremail"))
      );
      if (fleetList.length > 0) {
        localStorage.setItem("fleetData", JSON.stringify(fleetList[0]));
        navigate(`/fleetPage/${fleetList[0].fleetName}`, {
          state: {
            email: localStorage.getItem("useremail"),
            fleetData: fleetList[0],
          },
        });
      }
    }
    if (user && (user.status === "200" || user.status === 200)) {
      localStorage.setItem("useremail", user.data.username);
      localStorage.setItem("token", user.data.token);
      localStorage.setItem("role", user.data.role);
      localStorage.setItem("firstname", user.data.firstName);
      localStorage.setItem("lastname", user.data.lastName);
      dispatch(
        fleetListAndRobotStatusByUserEmail(localStorage.getItem("useremail"))
      );
      if (fleetList.length > 0) {
        notifySuccess();
        localStorage.setItem("fleetData", JSON.stringify(fleetList[0]));
        navigate(`/fleetPage/${fleetList[0].fleetName}`, {
          state: { email: user.data.username, fleetData: fleetList[0] },
        });
      }
    }
  }, [navigate, error, dispatch, user, fleetList]);

  return (
    <div className="DesktopMain-wrapper">
      <div className="Login_page-wholeMain-wrapper">
        <div className="LoginPage-Main-wrapper">
          <img
            src="/assets/images/OttonomyLogo.png"
            className="Login-page-form-logo-image"
          />
          <form onSubmit={formik.handleSubmit} className="Login-page-form">
            {/* <p className="Login-form-p">Login To dashboard</p>
          <h6>Enter you credentials below </h6> */}
            <div className="Loginpage=-main-form">
              <div className="Login_page_input_wholeLabel_wrapper">
                <InputLabel
                  style={{
                    textAlign: "left",
                    color: "#fff",
                    marginBottom: "8px",
                    fontSize: "13px",
                  }}
                >
                  {" "}
                  Email Id
                </InputLabel>
                <div className="Loginpage-main-form-inner-div">
                  <TextField
                    id="standard-size-normal"
                    fullWidth
                    variant="standard"
                    InputProps={{}}
                    value={formik.values.email}
                    name="email"
                    onChange={formik.handleChange}
                    placeholder="Email ID"
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                </div>
              </div>
              <div className="Login_page_input_wholeLabel_wrapper">
                <InputLabel
                  style={{
                    textAlign: "left",
                    color: "#fff",
                    marginBottom: "8px",
                    fontSize: "13px",
                  }}
                >
                  {" "}
                  Password
                </InputLabel>
                <div className="Loginpage-main-form-inner-div">
                  <TextField
                    sx={{ borderRadius: "0px", width: "100%" }}
                    fullWidth
                    id="password"
                    variant="standard"
                    name="password"
                    placeholder="Password"
                    type={formik.values.showPassword ? "password" : "text"}
                    InputProps={{
                      // startAdornment: (
                      // <InputAdornment position="start">
                      // <LockIcon style={{color:'rgb(189,196,224)', width:'30px', height:'30px'}}/>
                      // </InputAdornment>
                      // ),
                      endAdornment: (
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => {
                            formik.setFieldValue(
                              "showPassword",
                              !formik.values.showPassword
                            );
                            // console.log('showPassword',formik.values.showPassword)
                          }}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                          style={{
                            color: "rgb(189,196,224)",
                            marginRight: "2px",
                          }}
                        >
                          {formik.values.showPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      ),
                    }}
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.password && Boolean(formik.errors.password)
                    }
                    helperText={
                      formik.touched.password && formik.errors.password
                    }
                  />
                </div>
              </div>
              {/* <div className="Login_page_forgot_password_div_wrapper">
                <p style={{ cursor: 'pointer' }} onClick={() => {
                  navigate('/forgotpassword')
                }}> Forgot Password</p>
              </div> */}
            </div>
            <div className="Loginpage-btn-wrapper">
              <button type="submit" className="Loginpage-btn-wrapper-btn">
                {" "}
                Sign In
              </button>
            </div>
          </form>
        </div>
        {/* {isAuthenticated && 
    (  <>
        {JSON.stringify(user,null,2)}
        </>
     )}
     */}
      </div>
      <ToastContainer />
    </div>
  );
}

export default Login;
