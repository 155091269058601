import './App.css';
import Login from './components/Login';
import * as React from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Dashboard from './components/Dashboard';
import FleetPage from './subcomponents/FleetPage';
import RobotPage from './subcomponents/RobotPage';
import IndividualFleet from './subcomponents/IndividualFleet';
import RobotIndividual from './subcomponents/RobotIndividual';
import AddFleet from './subcomponents/AddFleet';
import AddRobot from './subcomponents/AddRobot';
import AddUser from './subcomponents/AddUser';
import UserList from './subcomponents/UserList';
import EditFleet from './subcomponents/EditFleet';
import EditRobot from './subcomponents/EditRobot';
import EditUser from './subcomponents/EditUser';
import IndividualUsers from './subcomponents/IndividualUsers';
import OrderList from './subcomponents/OrderList';
import AddOrder from './subcomponents/AddOrder';
import ForgotPassword from './components/ForgotPassword';
import OTPVerification from './components/OTPVerification';
import ChangePassword from './components/ChangePassword';
import Map from './components/Map/Map'
import MenuIcon from '@mui/icons-material/Menu';
import { Button, Drawer, Link } from '@mui/material';
import LogOutModal from './subcomponents/LogOutModal';
import { useState } from 'react';
import { useEffect } from 'react';

function App() {

  const dashboardopen=true
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const currentlocation = useLocation()
  const firstName = localStorage.getItem('firstname')
  const lastName = localStorage.getItem('lastname')
  const role =localStorage.getItem('role')
  const emailId = localStorage.getItem("useremail")
  const [currentcomponent, setcurrentcomponent] =useState('')
  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };
  const navigate= useNavigate()
 useEffect(()=>{
  setcurrentcomponent(currentlocation.pathname)
 },[])

  return (
    <div className="App">
    <div className="Hamburger_icon_wrapper"> 

     <div>   <img
              style={{ width: "auto", height: "33px" }}
              src="/assets/images/OttonomyLogo.png"
              className="logo-btm"
            /></div>
      {['right'].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)}> <MenuIcon/></Button>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            sx={{color:'#282F42'}}
            id="Responsive_drawer"
          >
          <ul
          className={"Dashboard_page_side_bar"}
          >
          <Link
            to="/dashboard"
            style={{ textDecoration: "none" }}
            className="Dashboard_side_bar_links"
            onClick={()=>{
              setcurrentcomponent('/dashboard')
              navigate('/dashboard')}}
          >
            <li
              className={currentcomponent==='/dashboard' ? "SideBar_active" : null}>
              <span>
              </span>
              <p
                style={{ marginBottom: "0px" }}
                className="Dashboard_side_bar_P"
              >
                Dashboard
              </p>
            </li>{" "}
          </Link>
          <Link
            to="/fleetPage"
            style={{ textDecoration: "none" }}
            className="Dashboard_side_bar_links"
            onClick={()=>{
              setcurrentcomponent('/fleetpage') 
            navigate('/fleetpage')}}
          >
            <li
              className={currentcomponent.startsWith("/fleetPage") || currentcomponent==='/fleetpage'? "SideBar_active" : null} >
              <span style={{ width: "25%" }}>
              </span>
              <p
                style={{ marginBottom: "0px" }}
                className="Dashboard_side_bar_P"
              >
                {" "}
               
                {/* {currentcomponent.startsWith("/fleetPage") &&
                <> Fleet
                <div className="side_bar_fleetdiv_wrapper">
                        <h6 className="SideBar_h6"> {currentcomponent.substr(11,currentcomponent.length)} </h6> 
                            <ul className="side_bar_ul"> 
                                <li className={currentcomponent === "robots" ? "side_bar_active side_bar_li" : "side_bar_li"} onClick={()=>{setcurrentcomponent('robots')}}> -Robots</li>
                                <li className={currentcomponent === "orders" ? "side_bar_active side_bar_li" : "side_bar_li"} onClick={()=>{setcurrentcomponent('orders')}}> -Orders </li>
                                <li className={currentcomponent === "videostream" ? "side_bar_active side_bar_li" : "side_bar_li"} onClick={()=>{setcurrentcomponent('videostream')}}> -Collective Videos</li>
                            </ul>
                   </div></> } */}
                  Fleets
              </p>
            </li>
          </Link>
          <Link
            to="/robotpage"
            style={{ textDecoration: "none" }}
            className="Dashboard_side_bar_links"
            onClick={()=>{
              setcurrentcomponent('/robotpage')
            navigate('/robotpage')}}
          >
            <li
              className={currentcomponent === "/robotpage" ? "SideBar_active" : null}
            >
              <span style={{ width: "25%" }}>
              </span>
              <p
                style={{ marginBottom: "0px" }}
                className="Dashboard_side_bar_P"
              >
                {" "}
                Robots
              </p>
            </li>
          </Link>
        </ul>
        <div
          className={'name-log_two' }
       >
          {" "}
          <div>
          <p className="SideBar_name_p">{firstName===undefined ||lastName===undefined ? <>{firstName}{lastName}</> :<>{emailId}</>} </p>
            <p
              className="SideBar_name_p"
              style={{ opacity: "0.7", fontSize: "11px" }}
            >
              {" "}
              {role}
            </p>
            </div>
            <LogOutModal />
          
        </div>
          </Drawer>
        </React.Fragment>
      ))}  
      </div>  
     <Routes> 
        <Route path="/" element={<Login/>}/>
        <Route path="/dashboard" element={<Dashboard dashboardopen={dashboardopen}/>}/>
        <Route path="/fleetPage" element={<FleetPage dashboardopen={dashboardopen}/>}/>
        <Route path="/robotpage" element={<RobotPage dashboardopen={dashboardopen}/>}/>
        <Route path="fleetPage/:fleetId" element={<IndividualFleet dashboardopen={dashboardopen}/>}/>
        <Route path="/maps" element={<Map dashboardopen={dashboardopen}/>}/>
        <Route path="robotPage/:robotId" element={<RobotIndividual dashboardopen={dashboardopen}/>}/>
        <Route path="/addfleet" element={<AddFleet dashboardopen={dashboardopen}/>}/>
        <Route path="/addrobot" element={<AddRobot dashboardopen={dashboardopen}/>}/>
        <Route path="/adduser" element={<AddUser dashboardopen={dashboardopen}/>}/>
        <Route path="/userlist" element={<UserList dashboardopen={dashboardopen}/>}/>
        <Route path="editfleet/:fleetId" element={<EditFleet dashboardopen={dashboardopen}/>}/>
        <Route path="editrobot/:robotId" element={<EditRobot dashboardopen={dashboardopen}/>}/>
        <Route path="edituser/:userid" element={<EditUser dashboardopen={dashboardopen}/>}/>
        <Route path="userlist/:userId" element={<IndividualUsers dashboardopen={dashboardopen}/>}/>
        <Route path="/orderlist" element={<OrderList dashboardopen={dashboardopen}/>}/>
        <Route path="/addorder" element={<AddOrder dashboardopen={dashboardopen}/>}/>
        <Route path="/forgotpassword" element={<ForgotPassword dashboardopen={dashboardopen}/>}/>
        <Route path="/otpverification" element={<OTPVerification dashboardopen={dashboardopen}/>}/>
        <Route path="/changepassword" element={<ChangePassword dashboardopen={dashboardopen}/>}/>
    </Routes> 
    </div>
  );
}

export default App;
