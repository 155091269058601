import { Autocomplete, FormControl, TextField } from "@mui/material";
import React, { useState } from "react";

const TransitDetails = ({
  orderlistData,
  orderStatusColor,
  editCancellationTime,
  editOrderCancellationTime,
  changeOrderCancTime,
  updatedCancellationTime,
  handleCancellationInputChange,
  setUpdatedCancellationTime,
  setEditCancellationTime,
  setDeletedTransitOrderId,
  setOpenRemoveTransitOrderModal,
  dropLocationOptions,
  item,
  confirmEditLocationModal,
  setConfirmEditLocationModal,
  setSelectedLocation,
  setSelectedOrderId,
  isLocationEditedToggle,
setIsLocationEditedToggle
}) => {
  const [isLocationEdited, setIsLocationEdited] = useState(false);
  return (
    <>
      <tr>
        <td> {item.robotId}</td>
        <td> {item.orderId}</td>
        <td>
          {" "}
          <div className="flex gap-x-3">
            <p className="self-center font-medium">
              {orderStatusColor(item.orderStatus, item.statusCode)}
              {item.statusCode < 9 && item.open_door_received && (
                "PIN Entered"
              )}
            </p>

            {item.noShowFlag === 1 && item.statusCode === 8 && (
              <div>
                {editCancellationTime !== item.orderId && (
                  <div className="flex gap-x-1">
                    <button
                      className="transition-all rounded-sm hover:bg-yellow-300 hover:shadow-md"
                      onClick={() =>
                        editOrderCancellationTime(item.orderId, true)
                      }
                      title="Edit Order Cancellation Time" // Add the title attribute for the tooltip
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        viewBox="0 0 20.652 20.652"
                        className="self-center"
                      >
                        <g
                          id="noun-edit-1150515"
                          transform="translate(-10.848 -10.849)"
                        >
                          <path
                            id="Path_250"
                            data-name="Path 250"
                            d="M31.307,15.7l-4.662-4.662a.659.659,0,0,0-.932,0L22.9,13.854a.657.657,0,0,0-.193.466.656.656,0,0,0,.193.466l4.2,4.2-7.88,7.879L15.5,23.144l7.02-6.951a.659.659,0,1,0-.928-.937l-7.4,7.326a.668.668,0,0,0-.1.085.654.654,0,0,0-.077.094l-1.786,1.768a.656.656,0,0,0-.182.338l-1.181,5.844a.659.659,0,0,0,.646.79.643.643,0,0,0,.131-.013l5.843-1.181a.655.655,0,0,0,.335-.18l13.49-13.49a.659.659,0,0,0,0-.932ZM17.026,29.053,12.351,30l.944-4.673,1.265-1.253L18.284,27.8Zm11-11-3.73-3.73,1.881-1.881,3.73,3.73Z"
                            fill="#02b7d4"
                          />
                        </g>
                      </svg>
                    </button>

                    <button
                      className="transition-all rounded-sm hover:bg-yellow-300 hover:shadow-md"
                      onClick={() => changeOrderCancTime(item.orderId, true)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14.078"
                        height="14.057"
                        viewBox="0 0 14.078 14.057"
                      >
                        <g
                          id="noun-delete-5593968"
                          transform="translate(-84 -14.395)"
                        >
                          <path
                            id="Path_119"
                            data-name="Path 119"
                            d="M157.573,115.376H151.03a2.05,2.05,0,0,1-2.048-2.048v-9.646h.741v9.646a1.309,1.309,0,0,0,1.307,1.307h6.542a1.309,1.309,0,0,0,1.307-1.307v-9.646h.741v9.646a2.05,2.05,0,0,1-2.048,2.048Z"
                            transform="translate(-63.262 -86.924)"
                            fill="#dc6068"
                          />
                          <path
                            id="Path_120"
                            data-name="Path 120"
                            d="M97.707,90.421H84.37a.37.37,0,0,1,0-.741H97.707a.37.37,0,0,1,0,.741Z"
                            transform="translate(0 -73.293)"
                            fill="#dc6068"
                          />
                          <path
                            id="Path_121"
                            data-name="Path 121"
                            d="M259.818,16.758h-.741V15.6a.469.469,0,0,0-.468-.468h-2.621a.469.469,0,0,0-.468.468v1.153h-.741V15.6a1.211,1.211,0,0,1,1.209-1.209h2.621a1.211,1.211,0,0,1,1.209,1.209Z"
                            transform="translate(-166.259)"
                            fill="#dc6068"
                          />
                          <path
                            id="Path_122"
                            data-name="Path 122"
                            d="M262.17,210.978a.37.37,0,0,1-.37-.37V205.17a.37.37,0,1,1,.741,0v5.437A.37.37,0,0,1,262.17,210.978Z"
                            transform="translate(-173.095 -185.366)"
                            fill="#dc6068"
                          />
                          <path
                            id="Path_123"
                            data-name="Path 123"
                            d="M410.57,210.978a.37.37,0,0,1-.37-.37V205.17a.37.37,0,1,1,.741,0v5.437A.37.37,0,0,1,410.57,210.978Z"
                            transform="translate(-317.568 -185.366)"
                            fill="#dc6068"
                          />
                        </g>
                      </svg>
                    </button>
                  </div>
                )}
                {editCancellationTime === item.orderId && (
                  <div className="flex gap-x-2">
                    <input
                      type="number"
                      placeholder="New Cancellation time"
                      className="px-3 border rounded-lg"
                      value={updatedCancellationTime}
                      onChange={(event) => handleCancellationInputChange(event)}
                    />
                    <button
                      onClick={() => changeOrderCancTime(item.orderId, false)}
                      className="p-1 text-white transition-all rounded-lg bg-ottonomyBlue hover:bg-blue-600"
                    >
                      Add
                    </button>
                    <button
                      onClick={() => {
                        setUpdatedCancellationTime("");
                        setEditCancellationTime(null);
                      }}
                      className="p-1 text-white transition-all rounded-lg bg-ottonomyBlue hover:bg-blue-600"
                    >
                      close
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        </td>
        <td> {item.cabinId}</td>
        <td>
          {isLocationEdited && isLocationEditedToggle ? (
            <FormControl
              style={{
                backgroundColor: "rgb(28, 30, 43)",
                WebkitTextFillColor: "white",
              }}
              sx={{
                fontSize: "10px",
              }}
            >
              <Autocomplete
                options={dropLocationOptions}
                value={
                  item?.delivery?.locationName && item?.delivery?.locationName
                }
                onChange={(_, selectedOption) => {
                  setConfirmEditLocationModal(true);
                  setSelectedLocation(selectedOption);
                  setSelectedOrderId(item.orderId);
                  // console.log(_);
                  console.log(selectedOption);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    InputLabelProps={{
                      shrink: true,
                      // style: {fontSize: 12}
                    }}
                    style={{
                      color: "white",
                      margin: "0",
                      padding: "5px 5px 5px 10px",
                      minWidth: "250px",
                    }}
                    sx={{
                      fontSize: "10px!important",
                    }}
                  />
                )}
              />
            </FormControl>
          ) : (
            <>
              {item?.delivery?.locationName
                ? item?.delivery?.locationName
                : parseFloat(item?.delivery?.positionX).toFixed(8) +
                  ", " +
                  parseFloat(item?.delivery?.positionY).toFixed(8) +
                  ", " +
                  parseFloat(item?.delivery?.positionYaw).toFixed(8)}
              {item.statusCode < 9 && (
                <button
                  className="ml-2 transition-all rounded-sm"
                  onClick={() => {setIsLocationEdited(true);setIsLocationEditedToggle(true)}}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 20.652 20.652"
                    className="self-center"
                  >
                    <g
                      id="noun-edit-1150515"
                      transform="translate(-10.848 -10.849)"
                    >
                      <path
                        id="Path_250"
                        data-name="Path 250"
                        d="M31.307,15.7l-4.662-4.662a.659.659,0,0,0-.932,0L22.9,13.854a.657.657,0,0,0-.193.466.656.656,0,0,0,.193.466l4.2,4.2-7.88,7.879L15.5,23.144l7.02-6.951a.659.659,0,1,0-.928-.937l-7.4,7.326a.668.668,0,0,0-.1.085.654.654,0,0,0-.077.094l-1.786,1.768a.656.656,0,0,0-.182.338l-1.181,5.844a.659.659,0,0,0,.646.79.643.643,0,0,0,.131-.013l5.843-1.181a.655.655,0,0,0,.335-.18l13.49-13.49a.659.659,0,0,0,0-.932ZM17.026,29.053,12.351,30l.944-4.673,1.265-1.253L18.284,27.8Zm11-11-3.73-3.73,1.881-1.881,3.73,3.73Z"
                        fill="#02b7d4"
                      />
                    </g>
                  </svg>
                </button>
              )}
            </>
          )}
        </td>
        <td> {item.phone}</td>
        <td> {item.pin}</td>
        <td>
          {" "}
          {new Date(item.createdAt)
            .toString()
            .replace("GMT+0530 (India Standard Time)", "")}
          {/*{.replace('GMT+0530 (India Standard Time)','')}  */}
        </td>
        {item.statusCode < 9 && (
          <button
            className="p-2 ml-6 transition-all rounded-lg hover:bg-ottonomyBlue hover:shadow-md"
            onClick={() => {
              setDeletedTransitOrderId(item.orderId);
              setOpenRemoveTransitOrderModal(true);
              // deleteOrderTransit(item.orderId)
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14.078"
              height="14.057"
              viewBox="0 0 14.078 14.057"
            >
              <g id="noun-delete-5593968" transform="translate(-84 -14.395)">
                <path
                  id="Path_119"
                  data-name="Path 119"
                  d="M157.573,115.376H151.03a2.05,2.05,0,0,1-2.048-2.048v-9.646h.741v9.646a1.309,1.309,0,0,0,1.307,1.307h6.542a1.309,1.309,0,0,0,1.307-1.307v-9.646h.741v9.646a2.05,2.05,0,0,1-2.048,2.048Z"
                  transform="translate(-63.262 -86.924)"
                  fill="#dc6068"
                />
                <path
                  id="Path_120"
                  data-name="Path 120"
                  d="M97.707,90.421H84.37a.37.37,0,0,1,0-.741H97.707a.37.37,0,0,1,0,.741Z"
                  transform="translate(0 -73.293)"
                  fill="#dc6068"
                />
                <path
                  id="Path_121"
                  data-name="Path 121"
                  d="M259.818,16.758h-.741V15.6a.469.469,0,0,0-.468-.468h-2.621a.469.469,0,0,0-.468.468v1.153h-.741V15.6a1.211,1.211,0,0,1,1.209-1.209h2.621a1.211,1.211,0,0,1,1.209,1.209Z"
                  transform="translate(-166.259)"
                  fill="#dc6068"
                />
                <path
                  id="Path_122"
                  data-name="Path 122"
                  d="M262.17,210.978a.37.37,0,0,1-.37-.37V205.17a.37.37,0,1,1,.741,0v5.437A.37.37,0,0,1,262.17,210.978Z"
                  transform="translate(-173.095 -185.366)"
                  fill="#dc6068"
                />
                <path
                  id="Path_123"
                  data-name="Path 123"
                  d="M410.57,210.978a.37.37,0,0,1-.37-.37V205.17a.37.37,0,1,1,.741,0v5.437A.37.37,0,0,1,410.57,210.978Z"
                  transform="translate(-317.568 -185.366)"
                  fill="#dc6068"
                />
              </g>
            </svg>
          </button>
        )}
        <td></td>
      </tr>
    </>
  );
};

export default TransitDetails;
