import React, { useState } from 'react';
import { Button, Modal, Box, Typography, TextField } from '@mui/material';

const LocationModal = ({open,title}) => {
const [name, setName] = useState('');
  const [positionX, setPositionX] = useState('');
  const [positionY, setPositionY] = useState('');
  const [positionYaw, setPositionYaw] = useState('');


//   const handleClose = () => {
//     setOpen(false);
//   };

  const handleSave = () => {
    console.log('Position X:', positionX);
    console.log('Position Y:', positionY);
    console.log('Position Yaw:', positionYaw);

    // Close the modal
    // handleClose();
  };

  return (
    <div>
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="bg-white w-96 p-6 rounded-lg text-white">
            <Typography variant="h6" id="modal-title">
              {title}
            </Typography>
            <TextField
              label="Location Name"
              fullWidth
              variant="outlined"
              margin="normal"
              value={name}
              onChange={(e) => setPositionX(e.target.value)}
            />
            <TextField
              label="Position X"
              fullWidth
              variant="outlined"
              margin="normal"
              value={positionX}
              onChange={(e) => setPositionX(e.target.value)}
            />
            <TextField
              label="Position Y"
              fullWidth
              variant="outlined"
              margin="normal"
              value={positionY}
              onChange={(e) => setPositionY(e.target.value)}
            />
            <TextField
              label="Position Yaw"
              fullWidth
              variant="outlined"
              margin="normal"
              value={positionYaw}
              onChange={(e) => setPositionYaw(e.target.value)}
            />
            <Box mt={2} display="flex" justifyContent="space-between">
              <Button variant="contained">
                Cancel
              </Button>
              <Button variant="contained" color="primary" onClick={handleSave}>
                Save
              </Button>
            </Box>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default LocationModal;
