import React, { useEffect } from "react";
import { useState } from "react";
import { ImStack } from "react-icons/im";
import { AiOutlinePlus } from "react-icons/ai";
import { AiOutlineMinus } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { dropofflocations, MapData, pickuplocations } from "../Data/MapData";
import Header from "../components/Header";
import DashboardSideBar from "./DashboardSideBar";
import FleetPageTopBar from "../components/FleetPageTopBar";
import DashboardBottomBar from "../components/DashboardBottomBar";
import Popper from "@mui/material/Popper";
import Grid from "@mui/material/Grid";
import Fade from "@mui/material/Fade";
import Paper from "@mui/material/Paper";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import "../App.css";
import axios from "axios";
import DashboardSidebarTwo from "./DashboardSidebarTwo";

const IndividualFleet = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [placement, setPlacement] = React.useState();
  const [degree, setDegree] = useState(0);
  const [displayone, setdisplayone] = useState(false);
  const [displaytwo, setdisplaytwo] = useState(false);
  const [displaythree, setdisplaythree] = useState(false);
  const [hidden, setHidden] = useState(true);
  const [hiddenL, setHiddenL] = useState(true);
  const [robotlistData, setrobotlistData] = useState();

  const fleetData = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (
      !localStorage.getItem("token") ||
      localStorage.getItem("token") === null ||
      localStorage.getItem("token") === "null" ||
      localStorage.getItem("token") === undefined
    ) {
      navigate("/");
    }
  }, []);
  const { fleetList: fleetDataList } = useSelector((state) => state.fleetList);

  const [topbar, settopbar] = useState("orders");

  useEffect(() => {
    MapData.forEach((item, key) => {
      item.sno = key + 1;
    });
    pickuplocations.forEach((item, key) => {
      item.sno = key + 1;
    });
    dropofflocations.forEach((item, key) => {
      item.sno = key + 1;
    });
  }, []);

  const handleChange = () => {
    setHidden(!hidden);
    setHiddenL(true);
    setDegree(hidden ? 0 : 180);
  };

  const handleChangeL = () => {
    setHiddenL(!hiddenL);
    setDegree(hiddenL ? 0 : 180);
  };

  const handleClick = (newPlacement, order) => (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
    if (order === "one") {
      setdisplayone(true);
      setdisplaytwo(false);
      setdisplaythree(false);
    } else if (order === "two") {
      setdisplayone(false);
      setdisplaytwo(true);
      setdisplaythree(false);
    } else {
      setdisplaythree(true);
      setdisplaytwo(false);
      setdisplayone(false);
    }
  };

  const steps = [
    "Order Recieved",
    "Enroute to pickup",
    "Reached Pickup Location",
    "Waiting for Pickup",
    "Order Picked Up",
    "Enroute to Drop-off Location",
    "Reached Drop-off Location",
    "Waiting for Drop Off",
    "Order Delivered",
  ];

  const isopen = useSelector((state) => state.dashboardopenReducer);
  const { sidebarcollapse } = useSelector((state) => state.login);

  return (
    <>
      <Header />
      <div className="container-fluid">
        <div className="row">
          <div
            className={sidebarcollapse ? "col-md-2 collapseWidth" : "col-md-2"}
          >
            <DashboardSideBar
              dashboardopen={props.dashboardopen}
              active="fleetpage"
            />
            <DashboardBottomBar active="fleetpage" />
          </div>

          <div
            className={`${
              sidebarcollapse
                ? "col-md-10 collapseWidth"
                : "map_col_10 col-md-10"
            }`}
          >
            <div
              style={{ height: "100%" }}
              className={isopen.userlogindata ? "" : ""}
            >
              <div className="FleetView_page_Image_Button_wrapper_wholeMain">
                {/* <div className="btn-grp">
                  <Grid item style={{ textAlign: "end" }}>
                    <Button
                      className="btn-top"
                      onClick={handleClick("bottom-end", "one")}
                    >
                      Current Order{" "}
                      <span className="arrow-btn">
                        <img
                          src="/assets/images/Drop down arrow.svg"
                          onClick={handleChange}
                          style={{ transform: `rotate(${degree}deg)` }}
                          alt=""
                        />
                      </span>
                    </Button>
                  </Grid>
                  <Grid item style={{ textAlign: "end" }}>
                    <Button
                      className="btn-top"
                      onClick={handleClick("bottom-end", "two")}
                    >
                      Last 10 Orders{" "}
                      <span className="arrow-btn">
                        <img
                          onClick={handleChangeL}
                          style={{ transform: `rotate(${degree}deg)` }}
                          src="/assets/images/Drop down arrow.svg"
                          alt=""
                        />
                      </span>
                    </Button>
                  </Grid>
                  <Grid item style={{ textAlign: "end" }}>
                    <Button
                      className="btn-top"
                      onClick={handleClick("bottom-end", "three")}
                    >
                      All Orders{" "}
                    </Button>
                  </Grid>
                  <Popper
                    open={open}
                    anchorEl={anchorEl}
                    placement={placement}
                    transition
                    style={{ zIndex: "9999999" }}
                  >
                    {({ TransitionProps }) => (
                      <Fade {...TransitionProps} timeout={350}>
                        <Paper>
                          {displayone ? (
                            <div className="FleetView_page_Popper_content_one_wrapper">
                              <div className="FleetView_page_Popper_content_one_wrapper_inner-one">
                                <h6> Order ID 00818</h6>
                                <p> 2022-01-29</p>
                                <p> 15.17.45</p>
                              </div>
                              <div className="FleetView_page_Popper_content_one_wrapper_inner_two">
                                <Box sx={{ width: "100%" }}>
                                  <Stepper activeStep={1} alternativeLabel>
                                    {steps.map((label) => (
                                      <Step
                                        style={{ color: "red" }}
                                        key={label}
                                      >
                                        <StepLabel>{label}</StepLabel>
                                      </Step>
                                    ))}
                                  </Stepper>
                                </Box>
                              </div>
                              <div className="FleetView_page_Popper_content_one_wrapper_inner-one">
                                <h6> CABIN N</h6>
                                <div>
                                  {" "}
                                  <ArrowUpwardIcon
                                    style={{ color: "green" }}
                                  />{" "}
                                  PickUp: Aisle 1
                                </div>
                                <div>
                                  {" "}
                                  <ArrowDownwardIcon
                                    style={{ color: "red" }}
                                  />{" "}
                                  Drop Off: Aisle 2
                                </div>
                              </div>
                            </div>
                          ) : displaytwo ? (
                            <div className="FleetView_page_Popper_content_two_wrapper">
                              <div className="FleetView_page_Popper_content_two">
                                <div className="FleetView_page_Popper_content_one_wrapper_inner-one">
                                  <h6> Order ID 00818</h6>
                                  <p> 2022-01-29</p>
                                  <p> 15.17.45</p>
                                </div>
                                <div className="FleetView_page_Popper_content_one_wrapper_inner-one">
                                  <h6> CABIN N</h6>
                                  <div>
                                    {" "}
                                    <ArrowUpwardIcon
                                      style={{ color: "green" }}
                                    />{" "}
                                    PickUp: Aisle 1
                                  </div>
                                  <div>
                                    {" "}
                                    <ArrowDownwardIcon
                                      style={{ color: "red" }}
                                    />{" "}
                                    Drop Off: Aisle 2
                                  </div>
                                </div>
                              </div>
                              <div className="FleetView_page_Popper_content_two">
                                <div className="FleetView_page_Popper_content_one_wrapper_inner-one">
                                  <h6> Order ID 00818</h6>
                                  <p> 2022-01-29</p>
                                  <p> 15.17.45</p>
                                </div>
                                <div className="FleetView_page_Popper_content_one_wrapper_inner-one">
                                  <h6> CABIN N</h6>
                                  <div>
                                    {" "}
                                    <ArrowUpwardIcon
                                      style={{ color: "green" }}
                                    />{" "}
                                    PickUp: Aisle 1
                                  </div>
                                  <div>
                                    {" "}
                                    <ArrowDownwardIcon
                                      style={{ color: "red" }}
                                    />{" "}
                                    Drop Off: Aisle 2
                                  </div>
                                </div>
                              </div>
                              <div className="FleetView_page_Popper_content_two">
                                <div className="FleetView_page_Popper_content_one_wrapper_inner-one">
                                  <h6> Order ID 00818</h6>
                                  <p> 2022-01-29</p>
                                  <p> 15.17.45</p>
                                </div>
                                <div className="FleetView_page_Popper_content_one_wrapper_inner-one">
                                  <h6> CABIN N</h6>
                                  <div>
                                    {" "}
                                    <ArrowUpwardIcon
                                      style={{ color: "green" }}
                                    />{" "}
                                    PickUp: Aisle 1
                                  </div>
                                  <div>
                                    {" "}
                                    <ArrowDownwardIcon
                                      style={{ color: "red" }}
                                    />{" "}
                                    Drop Off: Aisle 2
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <>ddfbdf</>
                          )}
                        </Paper>
                      </Fade>
                    )}
                  </Popper>
                </div> */}

                <div style={{ height: "100%" }} className="FleetPage_Top_bar">
                  <FleetPageTopBar
                    fleetData={
                      fleetData.state
                        ? fleetData.state.fleetData
                        : JSON.parse(localStorage.getItem("fleetData"))
                    }
                    robotlistData={robotlistData}
                    topbar={topbar}
                  />
                </div>
              </div>
              <div className="map-icons ">
                <ImStack size={34} className="fill-square" />
                <AiOutlinePlus size={34} className="fill-circle" />
                <AiOutlineMinus size={34} className="fill-circle" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default IndividualFleet;
