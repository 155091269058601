import React, { useEffect, useState } from "react";
import { FormControlLabel, Switch } from "@mui/material";
function Location({ details }) {
  return (
    <>
      <div className="bg-ottonomyDarkGrey h-[400px] text-white">
        <div className="flex p-3 justify-between border-b-2">
          <p className="text-white font-bold text-base">
            {details._id} LOCATIONS
          </p>
        </div>
        <div className="max-h-[300px] overflow-y-scroll mx-1 mt-1">
          <table className="table-auto w-full">
            <thead className="bg-ottonomyBlue sticky top-0 z-10">
              <tr>
                <th className="px-4 py-2">S.No</th>
                <th className="px-4 py-2">Location Name</th>
                <th className="px-4 py-2">Position</th>
                <th className="px-4 py-2">Status</th>
                <th className="px-4 py-2">Actions</th>
              </tr>
            </thead>
            <tbody className="overflow-y-scroll">
              {details?.locations?.map((item, index) => (
                <tr key={item.id}>
                  <td className="px-4 py-2">{index + 1}</td>
                  <td className="px-4 py-2">{item.locationName}</td>
                  <td className="px-4 py-2">{item.position}</td>
                  <td className="px-4 py-2">
                      <Switch
                        checked={item.status}
                        style={{ color: "#fff" }}
                        // onChange={() => {
                        //   setmapcheck(!mapcheck);
                        // }}
                      />
                  </td>
                  <td className="px-4 py-2 flex justify-center gap-3">
                    <button className="rounded-sm hover:scale-110 transform transition-transform">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20.652 20.652"
                        className="self-center"
                      >
                        <g
                          id="noun-edit-1150515"
                          transform="translate(-10.848 -10.849)"
                        >
                          <path
                            id="Path_250"
                            data-name="Path 250"
                            d="M31.307,15.7l-4.662-4.662a.659.659,0,0,0-.932,0L22.9,13.854a.657.657,0,0,0-.193.466.656.656,0,0,0,.193.466l4.2,4.2-7.88,7.879L15.5,23.144l7.02-6.951a.659.659,0,1,0-.928-.937l-7.4,7.326a.668.668,0,0,0-.1.085.654.654,0,0,0-.077.094l-1.786,1.768a.656.656,0,0,0-.182.338l-1.181,5.844a.659.659,0,0,0,.646.79.643.643,0,0,0,.131-.013l5.843-1.181a.655.655,0,0,0,.335-.18l13.49-13.49a.659.659,0,0,0,0-.932ZM17.026,29.053,12.351,30l.944-4.673,1.265-1.253L18.284,27.8Zm11-11-3.73-3.73,1.881-1.881,3.73,3.73Z"
                            fill="#02b7d4"
                          />
                        </g>
                      </svg>
                    </button>
                    <button className="rounded-sm hover:scale-110 transform transition-transform">
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        stroke-width="0"
                        viewBox="0 0 17 24"
                        class="w-5 h-5 text-[#E96C67]"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g>
                          <path fill="none" d="M0 0h24v24H0z"></path>
                          <path d="M7 4V2h10v2h5v2h-2v15a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V6H2V4h5zM6 6v14h12V6H6zm3 3h2v8H9V9zm4 0h2v8h-2V9z"></path>
                        </g>
                      </svg>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
export default Location;
